<style lang="scss" scoped>
.login {
	text-align: center;
	padding: 0 4vw;
}
.logo {
	width: 70vw;
	margin: 20vh auto 20px;
	img {
		width: 100%;
	}
}
.btn {
	margin-top: 40px;
}
.change_type {
	font-size: 14px;
	// text-align: left;
	color: $color_main;
	padding: 20px 0;
	background-color: #fff;
}
.tel {
	font-size: 14px;
	font-weight: bold;
	position: absolute;
	left: 0;
	bottom: 4vh;
	text-align: center;
	width: 100%;
	z-index: -1;
}
.send_msg_btn {
	color: #fff;
}
::v-deep .van-field__left-icon {
	display: flex;
	align-items: center;
}
.ybx_pic {
	margin-top: -5px;
}
.register {
	font-size: 14px;
	.color {
		color: $color_main;
	}
}
</style>

<template>
	<div class="login">
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信或支付宝中打开</h4>
		</div>
		<template v-else>
			<template v-if="!Boolean(userDesc)">
				<div class="logo">
					<img v-show="logo" :src="logo" alt="logo" />
				</div>

				<van-form ref="form" @submit="submit" input-align="center" error-message-align="right">
					<van-field v-model="mobile" size="large" name="mobile" type="number" left-icon="ybx_mobile" :rules="rules.mobile" placeholder="请输入手机号码">
						<template #left-icon>
							<van-icon class="iconfont ybx_mobile" />
						</template>
					</van-field>

					<!-- 密码登录 -->
					<van-field v-if="pwdLogin" v-model="password" size="large" name="password" :rules="rules.password" type="password" placeholder="请输入密码">
						<template #left-icon>
							<van-icon class="iconfont ybx_password" />
						</template>
					</van-field>

					<!-- 短信验证码登录 -->
					<template v-else>
						<van-field name="imgCode" v-model="imgCode" :rules="rules.imgCode" placeholder="请输入图片验证码">
							<template #left-icon>
								<van-icon class="iconfont ybx_pic" />
							</template>
							<template #extra>
								<van-image :src="txmImg" width="80px" @click="changeImg" fit="scale-down">
									<template #loading>
										<van-loading type="spinner" size="20" />
									</template>
								</van-image>
							</template>
						</van-field>
						<van-field name="msgCode" v-model="msgCode" :rules="rules.msgCode" placeholder="请输入短信验证码">
							<template #left-icon>
								<van-icon class="iconfont ybx_msg" />
							</template>
							<template #button>
								<van-button size="small" type="info" @click.stop="sendMsg" :disabled="waitMsg" :loading="msgLoading">
									<span v-show="!waitMsg">获取验证码</span>
									<van-count-down v-show="waitMsg" ref="countDown" class="time" :time="60000" @finish="finish">
										<template #default="timeData">
											<span class="send_msg_btn">{{ timeData.seconds }}秒</span>
										</template>
									</van-count-down>
								</van-button>
							</template>
						</van-field>
					</template>

					<div class="btn">
						<van-button type="info" block native-type="submit" :loading="loginLoading" loading-text="登录中...">登录</van-button>
					</div>
				</van-form>
				<div v-if="pwdLogin" @click="pwdLogin = !pwdLogin" class="change_type">短信验证码登录</div>
				<div v-else @click="pwdLogin = !pwdLogin" class="change_type">密码登录</div>
				<div v-if="pid" class="register" @click="register">
					<span>还没有账户？去</span>
					<span class="color"> 注册</span>
				</div>
				<div class="tel">
					报案电话：400-660-9195
					<page-footer></page-footer>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import { Field, DatetimePicker, Form, Icon, Button, CountDown, Image as VanImage, Loading, Toast } from 'vant';
import regular from '@/assets/js/regular';
import { logoMap, isDev } from '@/config/sundryParams';
import { login } from '@/request/billSearch';
import { http_getLoginStatus, http_getDict } from '@/request/common';
import { http_getMsgCode } from '@/request/proRegister.js';
import { loginNoPwd } from '@/request/api';

export default {
	name: 'login', // 查单系统登录页面
	components: {
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Form.name]: Form,
		[Icon.name]: Icon,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[VanImage.name]: VanImage,
		[Loading.name]: Loading,
	},
	data() {
		return {
			pid: '',
			userDesc: '',
			logo: '',
			mobile: '',
			password: '',
			imgCode: '',
			msgCode: '',

			key: '',
			txmImg: '',

			rules: {
				mobile: [
					{ required: true, message: '' },
					{ pattern: regular.phone, message: '请输入正确的手机号码' },
				],
				password: [{ required: true, message: '' }],
				imgCode: [{ required: true }],
				msgCode: [{ required: true }],
			},
			pwdLogin: true,

			msgLoading: false,
			waitMsg: false,
			loginLoading: false,

			weChatWarningShow: false,
		};
	},
	created() {
		this.pid = this.$route.query.pid;
		this.userDesc = this.$route.query.user;
		this.getLogoInfo();
		if (!isDev || this.$base.getEnv()) {
			if (this.userDesc) {
				this.loginNoPwd();
			} else {
				this.checkLogin();
				this.changeImg();
			}
		} else {
			this.weChatWarningShow = true;
		}
	},
	methods: {
		// 获取logo配置信息，并设置logo
		getLogoInfo() {
			http_getDict('h5OffLineLogo').then(res => {
				let logoInfo = res.filter(item => item.code === this.pid);
				if (logoInfo.length) {
					let key = logoInfo[0].value;
					this.logo = Object.hasOwnProperty.call(logoMap, key) ? logoMap[key] : logoMap.default;
				} else {
					this.logo = logoMap.default;
				}
			});
		},

		submit(values) {
			let send = {
				loginType: this.pwdLogin ? 'password' : 'mobile',
				mobile: this.mobile,
				password: this.pwdLogin ? this.password : this.msgCode,
			};
			login(send)
				.then(res => {
					localStorage.setItem('khsSundryUserId', res.userInfo.id);
					this.$router.push({
						path: 'goods',
						query: {
							pid: this.pid,
						},
					});
				})
				.finally(() => {
					this.loginLoading = false;
				});
		},

		// 检测登录状态
		checkLogin() {
			http_getLoginStatus().then(res => {
				if (res.userType == 3) {
					this.$router.push({
						path: 'goods',
						query: {
							pid: this.pid,
						},
					});
				}
			});
		},

		// 登录
		loginNoPwd() {
			loginNoPwd(this.userDesc).then(res => {
				localStorage.setItem('khsSundryUserId', res.userInfo.id);
				this.$router.push({
					path: 'goods',
					query: {
						pid: this.pid,
						user: this.userDesc,
					},
				});
			});
			return new Promise(resolve => {});
		},

		sendMsg() {
			this.$refs.form.validate(['mobile', 'imgCode']).then(() => {
				this.msgLoading = true;
				this.getMsgCode()
					.then(res => {
						if (this.$refs.countDown) {
							this.$refs.countDown.reset();
						}
						this.waitMsg = true;
						if (!Object.hasOwnProperty.call(res, 'code')) {
							Toast.success('获取成功');
						}
					})
					.finally(() => {
						this.msgLoading = false;
					});
			});
		},

		// 获取验证码
		getMsgCode() {
			let send = {
				code: this.imgCode,
				key: this.key,
				mobile: this.mobile,
				type: 'LOGIN',
			};
			return http_getMsgCode(send);
		},

		//改变图片验证码
		changeImg() {
			let time = new Date().getTime();
			this.key = time;
			this.txmImg = '/insurance/api/common/verifyCode/' + time;
		},

		finish() {
			this.waitMsg = false;
		},

		// 注册
		register() {
			this.$router.push({
				path: 'register',
				query: { pid: this.pid },
			});
		},
	},
};
</script>
