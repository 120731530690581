<template>
	<div>
		<div></div>
		<van-list class="list" v-if="list.length" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<van-cell v-for="(item, index) in list" :key="index">
				<div class="row_header">
					<span class="name" v-text="item.proName"></span>
					<span class="plain" v-text="item.comboName"></span>
					<span></span>
				</div>
				<div>
					<span class="label">保障期限：</span>
					<span v-text="`${$base.getDateTime(item.enableDate)} 至 ${$base.getDateTime(item.disEnableDate)}`"></span>
				</div>
				<div>
					<span class="label">被保险人：</span>
					<span v-text="item.assuredName"></span>
				</div>
				<div>
					<span class="label">保单号：</span>
					<span v-text="item.policyCode" class="color-primary" @click="goDetail(item)"></span>
				</div>
				<div class="fx-right">
					<van-button class="btn" type="info" size="small" @click="goDetail(item)">查看详情</van-button>
				</div>
			</van-cell>
		</van-list>
		<!-- 没有查询到信息 -->
		<empty v-if="showEmpty" />
	</div>
</template>

<script>
import { List, Cell, Button } from 'vant';
import { http_getPFileByassuredInfo } from '@/request/common';
import empty from '../../components/empty.vue';

export default {
	components: {
		[List.name]: List,
		[Cell.name]: Cell,
		[Button.name]: Button,
		empty,
	},
	data() {
		return {
			list: [],
			showEmpty: false,
			pageInfo: {
				page: 0,
				limit: 10,
			},
			total: 0,
			finished: false,
			loading: true,
		};
	},
	created() {
		document.title = '保单列表';
		this.getData();
	},
	methods: {
		getData() {
			const info = {
				...this.$route.query,
				...this.pageInfo,
			};
			http_getPFileByassuredInfo(info).then(res => {
				this.list = [...this.list, ...res.rows];
				this.total = res.total;

				if (!this.list.length) {
					this.showEmpty = true;
				}
				this.loading = false;
				if (this.list.length === res.total) {
					this.finished = true;
				}
			});
		},
		onLoad() {
			if (this.list.length <= this.total) {
				this.pageInfo.page = this.pageInfo.page + 1;
				this.loading = true;
				this.getData();
			}
		},
		goDetail(item) {
			this.$router.push({
				path: '/pFileSearch/detail',
				query: { billId: item.billId, assuredId: item.assuredId },
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.list {
	font-size: 0.24rem;
}
.row_header {
	font-weight: 500;
	font-size: 0.28rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.text-1 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: inline-block;
	width: 60%;
	white-space: nowrap;
}
.fx-right {
	display: flex;
	justify-content: flex-end !important;
	.btn {
		margin-left: 0.3rem;
		border-radius: 6px;
	}
}
.plain {
	margin-left: 0.1rem;
}
.van-cell {
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	margin: 0.2rem auto;
	padding: 0.3rem 0.2rem;
	box-sizing: border-box;
	width: 94%;
	border-radius: 10px;
}
.label {
	color: $font_color_info;
}
.color-primary {
	color: $b_color_main;
}
</style>
