<template>
	<div class="empty">
		<div class="empty-box">
			<div class="img-box">
				<img src="@/assets/icon/empty.svg" />
			</div>
			<div class="empty-text">{{ emptyText }}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		emptyText: {
			type: String,
			default: '未查询到信息',
		},
	},
};
</script>

<style lang="scss" scoped>
.empty {
	font-size: 0.24rem;
	height: 100vh;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	.empty-box {
		width: 5rem;
		height: 4rem;
		margin: auto;
		display: flex;
		flex-direction: column;
		.img-box {
			width: 100%;
			height: 60%;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.empty-text {
		text-align: center;
	}
}
</style>
