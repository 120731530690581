<style lang="scss" scoped>
.login {
	text-align: center;
	padding: 0 4vw;
}
.logo {
	margin-top: 20vh;
	width: 50vw;
	margin-bottom: 20px;
}
.btn {
	margin-top: 40px;
}
.change_type {
	font-size: 14px;
	// text-align: left;
	color: $color_main;
	padding: 20px 0;
	background-color: #fff;
}
.tel {
	font-size: 14px;
	font-weight: bold;
	position: absolute;
	left: 0;
	bottom: 4vh;
	text-align: center;
	width: 100%;
	z-index: -1;
}
.send_msg_btn {
	color: #fff;
}
::v-deep .van-field__left-icon {
	display: flex;
	align-items: center;
}
.ybx_pic {
	margin-top: -5px;
}
</style>

<template>
	<div class="login">
		<img src="@/assets/image/logo.png" alt="logo" class="logo" />

		<van-form ref="form" @submit="submit" input-align="center" error-message-align="right">
			<van-field v-model="mobile" size="large" name="mobile" type="number" left-icon="ybx_mobile" :rules="rules.mobile" placeholder="请输入手机号码">
				<template #left-icon>
					<van-icon class="iconfont ybx_mobile" />
				</template>
			</van-field>

			<!-- 密码登录 -->
			<van-field v-if="pwdLogin" v-model="password" size="large" name="password" :rules="rules.password" type="password" placeholder="请输入密码">
				<template #left-icon>
					<van-icon class="iconfont ybx_password" />
				</template>
			</van-field>

			<!-- 短信验证码登录 -->
			<template v-else>
				<van-field name="imgCode" v-model="imgCode" :rules="rules.imgCode" placeholder="请输入图片验证码">
					<template #left-icon>
						<van-icon class="iconfont ybx_pic" />
					</template>
					<template #extra>
						<van-image :src="txmImg" width="80px" @click="changeImg" fit="scale-down">
							<template #loading>
								<van-loading type="spinner" size="20" />
							</template>
						</van-image>
					</template>
				</van-field>
				<van-field name="msgCode" v-model="msgCode" :rules="rules.msgCode" placeholder="请输入短信验证码">
					<template #left-icon>
						<van-icon class="iconfont ybx_msg" />
					</template>
					<template #button>
						<van-button size="small" type="info" @click.stop="sendMsg" :disabled="waitMsg" :loading="msgLoading">
							<span v-show="!waitMsg">获取验证码</span>
							<van-count-down v-show="waitMsg" ref="countDown" class="time" :time="60000" @finish="finish">
								<template #default="timeData">
									<span class="send_msg_btn">{{ timeData.seconds }}秒</span>
								</template>
							</van-count-down>
						</van-button>
					</template>
				</van-field>
			</template>

			<div class="btn">
				<van-button type="info" block native-type="submit" :loading="loginLoading" loading-text="登录中...">登录</van-button>
			</div>
		</van-form>
		<div v-if="pwdLogin" @click="pwdLogin = !pwdLogin" class="change_type">短信验证码登录</div>
		<div v-else @click="pwdLogin = !pwdLogin" class="change_type">密码登录</div>
		<div class="tel">报案电话：400-660-9195</div>
	</div>
</template>

<script>
import { Field, DatetimePicker, Form, Icon, Button, CountDown, Image as VanImage, Loading, Toast } from 'vant';
import regular from '@/assets/js/regular';
import { login } from '@/request/billSearch';
import { http_getLoginStatus } from '@/request/common';
import { http_getMsgCode } from '@/request/proRegister.js';

export default {
	name: 'login', // 查单系统登录页面
	components: {
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Form.name]: Form,
		[Icon.name]: Icon,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[VanImage.name]: VanImage,
		[Loading.name]: Loading,
	},
	data() {
		return {
			mobile: '',
			password: '',
			imgCode: '',
			msgCode: '',

			key: '',
			txmImg: '',

			rules: {
				mobile: [
					{ required: true, message: '' },
					{ pattern: regular.phone, message: '请输入正确的手机号码' },
				],
				password: [{ required: true, message: '' }],
				imgCode: [{ required: true }],
				msgCode: [{ required: true }],
			},
			pwdLogin: true,

			msgLoading: false,
			waitMsg: false,
			loginLoading: false,
		};
	},
	created() {
		this.checkLogin();
		this.changeImg();
	},
	methods: {
		submit(values) {
			let send = {
				loginType: this.pwdLogin ? 'password' : 'mobile',
				mobile: this.mobile,
				password: this.pwdLogin ? this.password : this.msgCode,
			};
			login(send)
				.then(res => {
					this.$router.push('bill');
				})
				.finally(() => {
					this.loginLoading = false;
				});
		},

		// 检测登录状态
		checkLogin() {
			http_getLoginStatus().then(res => {
				if (res.userType == 3) {
					// this.$router.push("bill");
				}
			});
		},

		sendMsg() {
			this.$refs.form.validate(['mobile', 'imgCode']).then(() => {
				this.msgLoading = true;
				this.getMsgCode()
					.then(res => {
						if (this.$refs.countDown) {
							this.$refs.countDown.reset();
						}
						this.waitMsg = true;
						if (!Object.hasOwnProperty.call(res, 'code')) {
							Toast.success('获取成功');
						}
					})
					.finally(() => {
						this.msgLoading = false;
					});
			});
		},

		// 获取验证码
		getMsgCode() {
			let send = {
				code: this.imgCode,
				key: this.key,
				mobile: this.mobile,
				type: 'LOGIN',
			};
			return http_getMsgCode(send);
		},

		//改变图片验证码
		changeImg() {
			let time = new Date().getTime();
			this.key = time;
			this.txmImg = '/insurance/api/common/verifyCode/' + time;
		},

		finish() {
			this.waitMsg = false;
		},
	},
};
</script>
