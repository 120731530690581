<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}
.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}
.product_name {
	padding: 0.4rem 0.3rem;
	line-height: 0.3rem;
}
::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}

.add_user_btn {
	margin: 0.5rem 0.3rem 0.4rem;
	height: 0.9rem;
	border: 1px dashed #dddddd;
	display: flex;
	align-items: center;
	justify-content: center;
	.icon {
		width: 0.23rem;
		height: 0.22rem;
		background: url(../../assets/icon/add.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
	.text {
		font-size: 0.3rem;
		color: $color_main;
		margin-left: 0.2rem;
	}
}
::v-deep .main_row {
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;
	.cell_title,
	.cell_value {
		color: $font_color_thd;
		font-size: 0.24rem;
	}
}
::v-deep .checkbox {
	margin: 0.5rem 0.3rem;
	align-items: flex-start;
	.van-checkbox__icon {
		position: relative;
		top: 0.06rem;
		border-radius: 0.06rem;
		.van-icon {
			border-radius: 0.06rem;
		}
		padding-right: 0.2rem;
	}
	.van-checkbox__label {
		font-size: 0.24rem;
		line-height: 0.36rem;
		color: $font_color_thd;
		margin-left: 0;
	}
}
.is_link {
	color: $color_main;
}

.user_info_cell {
	align-items: center;
}
.user_icon {
	width: 0.28rem;
	height: 0.28rem;
	background: url(../../assets/icon/delete.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	margin-right: 0.16rem;
}
.user_icon,
.user_title {
	font-size: 0.28rem;
}

::v-deep .must_read_pop {
	.van-action-sheet__content {
		overflow: hidden;
	}
}
.must_read_content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 0 0.3rem;
	height: 70vh;
	box-sizing: border-box;
	.tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		.tab {
			display: flex;
			flex-direction: column;
			align-items: center;
			.text {
				font-size: 0.28rem;
				line-height: 0.36rem;
				color: $font_color_val;
			}
			.icon {
				width: 0.3rem;
				height: 0.06rem;
				margin-top: 0.16rem;
			}
		}
		.active {
			.text {
				color: $color_main;
			}
			.icon {
				background-color: $color_main;
			}
		}
	}
	.content {
		flex: 1;
		overflow-y: auto;
	}
	.loading_btn_box {
		width: 100%;
		padding: 0.2rem 0.3rem 1.2rem;
		box-sizing: border-box;
		background-color: #fff;
		.loading_btn {
			border-radius: 8px;
			height: 0.8rem;
		}
	}
	.content_info {
		word-break: break-all;
	}
}
.must_read_pop {
	z-index: 3001 !important;
	.loading_btn_content {
		display: flex;
		align-items: center;
		.time {
			color: #fff;
			margin-left: 0.4rem;
		}
	}
}

::v-deep .protect_user_title .text {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	.user_same {
		padding: 0 0.2rem;
		font-size: 0.24rem;
		line-height: 0.5rem;
		text-align: center;
		border-radius: 0.08rem;
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
	.btns {
		display: flex;
		justify-content: space-between;
		.name_paste {
			margin-right: 0.2rem;
		}
	}
}

.per_rule {
	margin-left: 0.2rem;
}

// TODO:css
::v-deep .name_paste_dialog {
	z-index: 3002 !important;
	.name_textarea {
		textarea {
			max-height: 50vh;
			overflow-y: auto;
			// background-color: $line_color;
			border: 1px solid $line_color;
			padding: 0.08rem;
		}
	}
	.van-cell::after {
		border: none;
	}
	.btns {
		display: flex;
		justify-content: space-around;
		padding-bottom: 10px;
		padding: 0 0.3rem 10px;
		button {
			width: 42%;
		}
	}

	.van-dialog__cancel {
		color: #323233;
		background-color: #fff;
		background: #fff;
		border: 1px solid #ebedf0;
		border-radius: 2px;
		margin-right: 0.1rem;
	}
	.van-dialog__confirm {
		border-radius: 2px;
		margin-left: 0.1rem;
	}
}
::v-deep .van-overlay {
	z-index: 3001 !important;
}

::v-deep .analysis_toast {
	z-index: 3003 !important;
}

.error_name_dialog {
	.statistics {
		padding: 0.3rem;
		font-weight: bold;
		font-size: 14px;
	}
	.num {
		font-size: 20px;
	}
	.error_num {
		color: $danger;
	}
	.title {
		padding: 0 0.3rem;
		font-size: 14px;
		line-height: 14px;
		position: relative;
		top: 6px;
		z-index: 1;
	}
	.error {
		color: $danger;
		font-size: 12px;
		line-height: 14px;
		position: relative;
		top: -6px;
		padding: 0 0.3rem;
		margin-bottom: 0.2rem;
	}
}

.error {
	color: $danger;
	font-size: 12px;
	line-height: 14px;
	position: relative;
	top: -6px;
	padding: 0 0.3rem;
	margin-bottom: 0.2rem;
}
.user_list_error_text {
	color: $danger;
}
</style>

<template>
	<div class="create_order" id="create_order">
		<top-nav @back="back">创建保单</top-nav>
		<div class="main_title product_name" v-text="productInfo.name"></div>

		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<!-- 投保信息 -->
			<active-title>选择投保信息</active-title>
			<van-cell-group :border="false">
				<van-field label="保障期限" :value="proteceInfo.time" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="picker" placeholder="请选择保障期限" @click="timePop = true" :rules="rules.time" />
				<van-field label="起保日期" :value="proteceInfo.start" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" placeholder="请选择起保日期" @click="startDatePop = true" :rules="rules.start" />
				<van-field label="终保日期" :value="proteceInfo.end" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" placeholder="请选择终保日期" @click="endDatePop = true" :rules="rules.end" />
				<van-field v-if="travelShow" label="旅行目的地" :value="proteceInfo.travel" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="picker" placeholder="请选择旅行目的地" @click="travelPop = true" :rules="travelRule" />
			</van-cell-group>
			<div class="line"></div>

			<!-- 投保人信息 -->
			<active-title>投保人信息</active-title>
			<van-cell-group :border="false" v-if="userTypeDict">
				<van-cell v-if="readonlyKeys.includes('userType')" title-class="cell_title" value-class="cell_value" :border="false" title="投保人类型" :value="sympleInfo.userType"></van-cell>
				<van-field v-else label="投保人类型" :value="userInfo.userType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择投保人类型" @click="insureTypeSelectClick('userType', 'userTypePop')" />

				<template v-if="userTypeDict.name == 1">
					<van-cell v-if="readonlyKeys.includes('userName')" title-class="cell_title" value-class="cell_value" :border="false" title="姓名" :value="sympleInfo.userName"></van-cell>
					<van-field v-else label="姓名" v-model="userInfo.userName" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保人姓名" :rules="rules.userName" />
				</template>

				<template v-if="userTypeDict.name == 2">
					<van-cell v-if="readonlyKeys.includes('userName')" title-class="cell_title" value-class="cell_value" :border="false" title="企业名称" :value="sympleInfo.userName"></van-cell>
					<van-field v-else label="企业名称" v-model="userInfo.userName" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写企业名称" :rules="rules.userName" />
				</template>

				<template v-if="userTypeDict.name == 1">
					<van-cell v-if="readonlyKeys.includes('cardType')" title-class="cell_title" value-class="cell_value" :border="false" title="证件类型" :value="sympleInfo.cardType"></van-cell>
					<van-field v-else label="证件类型" :value="userInfo.cardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择证件类型" @click="insureTypeSelectClick('cardType', 'cardTypePop')" />
				</template>

				<template v-if="userTypeDict.name == 2">
					<van-cell v-if="readonlyKeys.includes('groupCardType')" title-class="cell_title" value-class="cell_value" :border="false" title="证件类型" :value="sympleInfo.cardType"></van-cell>
					<van-field label="证件类型" :value="userInfo.groupCardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择证件类型" @click="insureTypeSelectClick('groupCardType', 'groupCardTypePop')" />
				</template>

				<van-cell v-if="readonlyKeys.includes('cardNum')" title-class="cell_title" value-class="cell_value" :border="false" title="证件号码" :value="sympleInfo.cardNum"></van-cell>
				<van-field v-else label="证件号码" v-model="userInfo.cardNum" :formatter="idCardNoFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" @input="cardNumChange" placeholder="请填写投保人证件号码" :rules="rules.cardNum" />

				<template v-if="userTypeDict.name == 1">
					<van-cell v-if="readonlyKeys.includes('birthday')" title-class="cell_title" value-class="cell_value" :border="false" title="出生日期" :value="sympleInfo.birthday"></van-cell>
					<van-field v-else label="出生日期" :value="userInfo.birthday" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.birthday" placeholder="请选择出生日期" @click="insureTypeSelectClick('birthday', 'birthdayPop')" />

					<van-cell v-if="readonlyKeys.includes('sex')" title-class="cell_title" value-class="cell_value" :border="false" title="性别" :value="sympleInfo.sex"></van-cell>
					<van-field v-else label="性别" :value="userInfo.sex" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.sex" placeholder="请选择性别" @click="insureTypeSelectClick('sex', 'sexPop')" />
				</template>

				<van-cell v-if="readonlyKeys.includes('phone')" title-class="cell_title" value-class="cell_value" :border="false" title="手机号码" :value="sympleInfo.phone"></van-cell>
				<van-field v-else label="手机号码" v-model="userInfo.phone" :formatter="mobileFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保人手机号码" :rules="rules.phone" />

				<van-cell v-if="readonlyKeys.includes('email')" title-class="cell_title" value-class="cell_value" :border="false" title="电子邮箱" :value="sympleInfo.email"></van-cell>
				<van-field v-else label="电子邮箱" v-model="userInfo.email" :formatter="emailFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保人电子邮箱" />

				<van-cell v-if="readonlyKeys.includes('userName')" title-class="cell_title" value-class="cell_value" :border="false" title="发票抬头" :value="sympleInfo.userName"></van-cell>
				<van-field v-else label="发票抬头" v-model="userInfo.userName" disabled label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写发票抬头" />

				<div class="line"></div>
				<van-field label="购买份数" v-model="userInfo.buyNum" disabled label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" />
				<div class="line"></div>
			</van-cell-group>
		</van-form>

		<!-- 被保险人 -->
		<active-title class="protect_user_title">
			<span v-text="`被保人列表(${userList.length}/200)`"></span>
			<div class="btns">
				<div class="user_same name_paste" @click="openNamePasteDialog">名单粘贴</div>
				<div class="user_same" v-if="userTypeDict && userTypeDict.name == 1 && showSameInsuerInfoBtn" @click="userSame">同投保人</div>
			</div>
		</active-title>
		<van-cell-group :border="false" v-show="userList.length">
			<van-cell v-for="(item, index) in userList" :key="index" @click="editUser(item)" title-class="cell_title" value-class="cell_value" class="user_info_cell" :border="true" is-link>
				<span class="user_icon" slot="icon" @click.stop="deleteUser(item.id)"></span>
				<span class="user_title" slot="title" v-text="item.userName"></span>
				<span slot="default" class="user_list_error_text">
					<span v-if="item.isError">信息中有误</span>
					<span v-if="item.isError && item.isRepeat">，</span>
					<span v-if="item.isRepeat">证件号码重复</span>
				</span>
			</van-cell>
		</van-cell-group>

		<div class="add_user_btn" @click="addUser">
			<span class="icon"></span>
			<span class="text">新增被保险人</span>
		</div>

		<van-cell title="受益人信息" value="法定受益人" title-class="cell_title" class="no_link main_row" value-class="cell_value" :border="false" is-link />
		<div class="line"></div>
		<van-field label="紧急联系人" v-model="emergentPhone" input-align="right" class="no_link main_row" label-class="cell_title" value-class="cell_value" :border="false" is-link />
		<div class="line"></div>

		<van-checkbox class="checkbox" v-model="rule_1" shape="square" icon-size="0.28rem" label-disabled>
			<span @click="rule_1 = !rule_1">本人已充分理解并同意</span>
			<span class="is_link" @click="seeRules">《保险条款》</span>
			<span class="is_link" @click="seeRule('投保声明')">《投保声明》</span>
			<br />
			<span class="is_link per_rule" @click="seeRule('个人信息保护政策')">《个人信息保护政策》</span>
		</van-checkbox>
		<van-checkbox class="checkbox" v-model="rule_2" shape="square" icon-size="0.28rem" @click="rule_2_check">
			本人已充分理解并同意
			<span class="is_link">《免责内容》</span>
			<span class="is_link">《被保险人同意声明》</span>
			<br />
			<span class="is_link per_rule">《投保提示》</span>
		</van-checkbox>

		<price-buy-btn v-show="buyBtnShow" :price="price" @buy="buy"></price-buy-btn>

		<!-- 弹出层 -->
		<!-- 为谁投保 -->
		<van-popup v-model="protectPersonPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="为谁投保" show-toolbar :visible-item-count="3" :columns="personList" @confirm="personCheck" @cancel="protectPersonPop = false" />
		</van-popup>
		<!-- 起保日期 -->
		<van-calendar v-model="startDatePop" @confirm="startDateCheck" :min-date="startDateMinDate" color="#2594EF" :formatter="$base.calendarFormatter" />
		<!-- 终保日期 -->
		<van-calendar v-model="endDatePop" @confirm="endDateCheck" :default-date="new Date(proteceInfo.end)" :min-date="endDateMinDate" :max-date="endDateMaxDate" color="#2594EF" :formatter="$base.calendarFormatter" />
		<!-- 保障期限 -->
		<van-popup v-model="timePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="保障期限" show-toolbar value-key="view_time" :visible-item-count="3" :columns="timeList" @confirm="timeCheck" @cancel="timePop = false" />
		</van-popup>

		<!-- 投保人类型 -->
		<van-popup v-model="userTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="投保人类型" show-toolbar value-key="value" :visible-item-count="3" :columns="userTypeList" @confirm="userTypeCheck" @cancel="userTypePop = false" />
		</van-popup>
		<!-- 个人证件类型 -->
		<van-popup v-model="cardTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="证件类型" show-toolbar value-key="label" :visible-item-count="3" :columns="cardTypeList" @confirm="cardTypeCheck" @cancel="cardTypePop = false" />
		</van-popup>
		<!-- 团体证件类型 -->
		<van-popup v-model="groupCardTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="证件类型" show-toolbar value-key="label" :visible-item-count="3" :columns="groupCardTypeList" @confirm="groupCardTypeCheck" @cancel="groupCardTypePop = false" />
		</van-popup>
		<!-- 出生日期 -->
		<van-popup v-model="birthdayPop" position="bottom" :style="{ height: '30vh' }">
			<van-datetime-picker type="date" v-model="currentDate" title="选择年月日" @cancel="birthdayPop = false" @confirm="birthdayCheck" visible-item-count="3" :min-date="birthdayMinDate" />
		</van-popup>
		<!-- 性别 -->
		<van-popup v-model="sexPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="性别" show-toolbar value-key="label" :visible-item-count="3" :columns="sexList" @confirm="sexCheck" @cancel="sexPop = false" />
		</van-popup>
		<bottom-info-pop v-model="infoPopShow" :title="infoPopTitle" :content="infoPopContent"></bottom-info-pop>

		<van-action-sheet v-model="mustReadPopShow" class="must_read_pop" title="请确认以下内容">
			<div class="must_read_content">
				<div class="tabs">
					<div class="tab" :class="{ active: mustReadIndex === 1 }">
						<span class="text">《免责内容》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 2 }">
						<span class="text">《被保险人同意声明》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 3 }">
						<span class="text">《投保提示》</span>
						<span class="icon"></span>
					</div>
				</div>
				<div class="content">
					<div class="content_info" v-show="mustReadIndex === 1" v-html="information.preventDuty">免责内容</div>
					<div class="content_info" v-show="mustReadIndex === 2" v-html="information.insuredDeclare">被保险人同意声明</div>
					<div class="content_info" v-show="mustReadIndex === 3" v-html="information.insuranceTip || information.hint">投保提示</div>
				</div>
                <div class="loading_btn_box safari_only">
					<van-button class="loading_btn" @click="nextDoc" :disabled="isReading" type="info" color="#2594EF" block="" size="normal">
						<div class="loading_btn_content">
							<span v-show="mustReadIndex === 1">已阅读并同意《免责内容》</span>
							<span v-show="mustReadIndex === 2">已阅读并同意《被保险人同意声明》</span>
							<span v-show="mustReadIndex === 3">已阅读并同意《投保提示》</span>
							<van-count-down v-show="isReading" ref="countDown" class="time" :time="200" @finish="finish">
								<template #default="timeData">
									<span class="block">{{ timeData.seconds }}秒</span>
								</template>
							</van-count-down>
						</div>
					</van-button>
				</div>
			</div>
		</van-action-sheet>

		<!-- TODO:名单粘贴 -->
		<van-dialog className="name_paste_dialog" v-model="namePasteDialogShow" :show-confirm-button="false" title="名单粘贴">
			<van-field
				class="name_textarea"
				v-model.trim="nameString"
				rows="8"
				autosize
				type="textarea"
				border
				autofocus
				placeholder="请按规则输入名单数据，姓名中间存在多个空格会解析失败。
正确规则如：
	张三  身份证  110101199901017917
	张红  女  E12345678  1980-01-10"></van-field>
			<p v-if="$store.state.productId == 27555" class="error">*证件类型只能为身份证</p>
			<div class="btns">
				<van-button type="default" @click="namePasteDialogShow = false">取消</van-button>
				<van-button type="info" @click="analysisNameStr" :disabled="!nameString" :loading="analysisIng" loading-text="解析中...">确定</van-button>
			</div>
		</van-dialog>

		<!-- TODO:错误提示 -->
		<van-dialog className="name_paste_dialog error_name_dialog" v-model="namePasteErrorDialogShow" :show-confirm-button="false" title="错误提示">
			<p class="statistics">
				解析
				<span class="total_num num" v-text="nameSuccessLength + nameErrorLength">0</span>
				条数据，成功
				<span class="success_num num" v-text="nameSuccessLength">0</span>
				条，失败
				<span class="error_num num" v-text="nameErrorLength">0</span>
				条
			</p>
			<p class="title">解析失败数据：</p>
			<van-field class="name_textarea" v-model="errorNameString" rows="8" autosize type="textarea"></van-field>
			<p class="error">可能的错误原因：输入不规范、证件号码错误</p>
			<div class="btns">
				<van-button type="default" @click="namePasteErrorDialogShow = false">关闭</van-button>
				<van-button type="info" ref="copyBtn" :data-clipboard-text="errorNameString">复制错误信息</van-button>
			</div>
		</van-dialog>

		<m-picker v-model="travelPop" :columns="travelPlaces" title="旅行目的地" valueKey="name" @confirm="travelCheck" @cancel="travelPop = false"></m-picker>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, ActionSheet, Button, CountDown, Form, Toast, Dialog } from 'vant';
import { orderFormConfig, fixedInfo, productDictInfo, getPrice, submitBill, analysisNameString } from '@/request/api';
import { http_getServerTime } from '@/request/common';
import { cardType, groupCardType, sexs } from '@/config/fixedParams';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import ClipboardJS from 'clipboard';
import vip from '@/config/customization';
import mPicker from '../../components/mPicker.vue';
import {obscureWordString} from "@/assets/js/obscureWordString"
export default {
	name: 'createOrder',
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Form.name]: Form,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,
		mPicker,
	},
	data() {
		return {
			scrollY: 0,
			information: {},
			productInfo: {},
			product: {},

			protectPersonPop: false,
			personList: ['本人', '爸爸', '妈妈', '妈妈1', '妈妈2', '妈妈3', '妈妈4'],

			// 投保信息日历弹出层
			startDatePop: false,
			endDatePop: false,
			endDateMinDate: new Date(),
			endDateMaxDate: new Date(),
			// 保障期限
			timeLimitDict: null,
			timePop: false,
			timeList: [],
			proteceInfo: {
				time: '',
				start: '',
				end: '',
				travel: '',
			},

			// 投保信息-脱敏后的信息
			sympleInfo: {
				userType: '',
				userName: '',
				cardType: '',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				email: '',
			},

			// 投保人信息
			userInfo: {
				userType: '',
				userName: '',
				cardType: '',
				groupCardType: '',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				email: '',
				buyNum: '1',
			},
			userTypeDict: null,

			rules: {
				time: [{ required: true }],
				start: [{ required: true }],
				userName: [{ required: true }, { pattern:new RegExp(`^[a-zA-Z()（）·]+$|^[\\u4e00-\\u9fa5()（）${obscureWordString}·]+$`), message: '只能输入中文或英文以及()·' }, { pattern: new RegExp(`[a-zA-Z]{4,}|[\\u4e00-\\u9fa5${obscureWordString}]{2,}`), message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				cardNum: [{ required: true }, { validator: this.cardNumCheck, message: '证件号码有误' }],
				birthday: [{ required: true }],
				sex: [{ required: true }],
				phone: [{ required: true }, { validator: this.phoneCheck, message: '请填写正确的手机号码' }],
			},

			userTypePop: false,
			cardTypePop: false,
			birthdayPop: false,
			currentDate: new Date(),
			userTypeList: [],
			// 个人证件类型
			cardTypeList: [],
			// 团体证件类型
			groupCardTypePop: false,
			groupCardTypeList: [],
			birthdayMinDate: new Date('1900/01/01'),
			birthday: '1990/01/01',
			sexPop: false,
			sexList: [],

			// 被保险人
			userList: [],

			// 紧急联系人
			emergentPhone: '',

			// 条款
			rule_1: false,
			rule_2: false,
			// 投保声明、个人信息保护政策内容
			rule_1_list: [],
			infoPopShow: false,
			infoPopTitle: '',
			infoPopContent: '',

			mustReadPopShow: false,
			mustReadIndex: 1,
			isReading: true,

			buyBtnShow: true,
			price: 0,

			startDateMinDate: new Date(),
			startSecond: '00:00:00',
			isNextSecondStart: false, // 是否即时起保

			// TODO:名单粘贴弹出层
			namePasteDialogShow: false,
			nameString: '',
			analysisIng: false,

			namePasteErrorDialogShow: false,
			nameSuccessLength: 0,
			nameErrorLength: 0,
			errorNameString: '',

			readonlyKeys: [], // 投保人信息只读Key

			showSameInsuerInfoBtn: true, // 同投保人 按钮显示控制

			isFixed: false,

			// 旅行目的地
			travelShow: false,
			travelFieldInfo: {},
			travelRule: [],
			travelPop: false,
			travelPlaces: [],
			travelCheckedList: [],
		};
	},
	watch: {
		'$route': {
			handler: function (route) {
				let params = route.params;
				if (params.hasOwnProperty('productInfo')) {
					this.productInfo = params.productInfo;
					this.information = params.information;
				}

				if (params.hasOwnProperty('product')) {
					this.product = params.product;
					this.setStartDate();
				}

				if (params.hasOwnProperty('userInfo')) {
					this.setUserInfo(params.userInfo);

					// 执行校验方法
					this.chekUserInfoList();

					// 执行重复检查
					this.validateRepeat();

					// 刷新DOM
					this.$forceUpdate();
				}
			},
			immediate: true,
		},

		// 价格计算
		'proteceInfo.time': function (val) {
			if (this.userList.length) {
				this.getPrice(this.userList.length);
			}
		},
		'userList.length': function (val) {
			if (val) {
				this.getPrice(val);
			}
		},
	},
	created() {
		this.initFixedParams();
		this.getCode();
		this.setFixdInfo();
		this.getProductInfo();
		this.getRules();
		// this.setBtnShowByKeyboard();
	},
	beforeRouteLeave(to, from, next) {
		if (to.name == 'productDetail') {
			khs.stop();
		}
		this.scrollY = document.querySelector('html').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
	methods: {
		// 旅行目的地选择
		travelCheck(val) {
			this.proteceInfo.travel = val.map(item => item.name).join(',');
			this.travelCheckedList = val;
			this.travelPop = false;
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase().replace(/ /g, '');
		},

		// 身份证号码格式化
		idCardNoFormatter(v) {
			return String(v).toLocaleUpperCase().replace(/ /g, '');
		},

		// 手机号码格式化
		mobileFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 邮箱格式化
		emailFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 投保人信息-下拉框被点击
		insureTypeSelectClick(modelKey, popKey) {
			if (this.readonlyKeys.includes(modelKey)) {
				return;
			}
			this[popKey] = true;
		},
		// 设置固定信息
		setFixdInfo() {
			let conf = vip[this.$store.state.user];
			if (conf) {
				if (conf.fixedInsureInfo && (!conf.product || conf.product === this.$store.state.productId)) {
					Object.assign(this.userInfo, conf.fixedInsureInfo);
					this.readonlyKeys = Object.keys(conf.fixedInsureInfo);

					// 设置DOM类型为下拉框类型的变量，对应的下拉框所选的对象
					// 用户类型
					if (Object.hasOwnProperty.call(conf.fixedInsureInfo, 'userType')) {
						let filterAry = this.userTypeList.filter(item => item.value === conf.fixedInsureInfo.userType);
						this.userTypeDict = filterAry.length ? filterAry[0] : null;
					}
					// 个人证件类型、团体证件类型，由于接口传递参数是中文名称，所以代码中没有使用下拉框所选对象的形式写法，不用设置

					this.setSympleInfo();
				}

				if (conf.fixedInsureInfo && conf.fixedInsureInfo.userType === '个人') {
					this.showSameInsuerInfoBtn = false;
				}
			}
		},

		// 设置脱敏信息
		setSympleInfo() {
			this.sympleInfo.userType = this.userInfo.userType;
			this.sympleInfo.userName = this.sympleFormatter(this.userInfo.userName);
			this.sympleInfo.phone = this.sympleFormatter(this.userInfo.phone, 'mobile');
			this.sympleInfo.email = this.sympleFormatter(this.userInfo.email, 'email');
			if (this.userInfo.userType === '个人') {
				this.sympleInfo.cardType = this.userInfo.cardType;
				this.sympleInfo.cardNum = this.sympleFormatter(this.userInfo.cardNum, 'idCard');
				this.sympleInfo.birthday = this.userInfo.birthday;
				this.sympleInfo.sex = this.userInfo.sex;
			} else {
				this.sympleInfo.cardType = this.userInfo.groupCardType;
				this.sympleInfo.cardNum = this.sympleFormatter(this.userInfo.cardNum);
			}
			this.isFixed = true;
		},

		// 脱敏 type: mobile,idCard,email
		sympleFormatter(str = '', type = '') {
			if (type === 'mobile') {
				return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
			} else if (type === 'idCard') {
				return str.slice(0, 14).padEnd(18, '*');
			} else if (type === 'email') {
				if (str) {
					return this.sympleFormatter(str.split('@')[0]) + '@' + str.split('@')[1];
				} else {
					return '';
				}
			} else {
				if (str.length <= 3) {
					return str.charAt(0).padEnd(str.length, '*');
				} else if (str.length > 3 && str.length <= 6) {
					return str.charAt(0).padEnd(str.length - 1, '*') + str.charAt(str.length - 1);
				} else {
					return str.slice(0, 2).padEnd(str.length - 2, '*') + str.slice(str.length - 2);
				}
			}
		},
		setStartDate() {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				this.startDateMinDate = new Date();
			} else {
				this.startDateMinDate = new Date(Date.now() + 8.64e7);
			}
		},
		// 获取代码型字段列表
		getCode() {
			orderFormConfig(this.$store.state.productId).then(res => {
				if (res) {
					// 投保人类型
					if (res.insurer) {
						let insureTypeList = res.insurer.filter(item => item.showName === '投保人类型');
						if (insureTypeList.length) {
							let dict = insureTypeList[0];
							this.userTypeList = JSON.parse(dict.validateRules).valueLimit;
							// if(this.$store.state.productId == '46621') {
							if (this.$store.state.productId == '84713' || this.$store.state.productId == '84948' || this.$store.state.productId == '87646') {
								this.userTypeList = this.userTypeList.filter(item => item.value === '个人');
							}
							if (this.userTypeList.length) {
								this.userTypeDict = this.userTypeList[0];
								this.userInfo.userType = this.userTypeList[0].value;

								// 固定信息
								this.setFixdInfo();
							}
						}
					}

					// 旅行目的地
					if (res.otherInfo) {
						let travelList = res.otherInfo.filter(item => item.fieldName === 'fieldName' || item.showName === '旅行目的地');
						if (travelList.length) {
							let field = travelList[0];
							this.travelFieldInfo = field;
							this.travelPlaces = JSON.parse(field.validateRules).valueLimit;
							this.travelRule = [{ required: field.required }];
							this.travelShow = true;
						}
					}
				}
			});
		},

		// 根据键盘弹出隐藏，设置按钮的显示与否
		setBtnShowByKeyboard() {
			let _this = this;
			let originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
			window.onresize = function () {
				let resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
				if (resizeHeight < originalHeight) {
					// 窗口被挤压，隐藏按钮
					_this.buyBtnShow = false;
				} else {
					// 还原按钮
					_this.buyBtnShow = true;
				}
			};
		},

		// 初始化固定参数
		initFixedParams() {
			this.cardTypeList = cardType;
			this.groupCardTypeList = groupCardType;
			this.userInfo.cardType = cardType[0].label;
			this.userInfo.groupCardType = groupCardType[0].label;
			this.sexList = sexs;
		},
		// 获取产品信息
		getProductInfo() {
			productDictInfo(this.$store.state.productId, this.$store.state.plainId).then(res => {
				this.timeList = res.insureTimeList;
				if (res.insureTimeList.length) {
					this.timeCheck(res.insureTimeList[0]);
				}
			});
		},

		// 获取投保声明、个人信息保护政策
		getRules() {
			fixedInfo().then(res => {
				this.rule_1_list = res.fixedInfo;
			});
		},

		back() {
			this.$router.push({ name: 'productDetail' });
		},

		personCheck(val) {
			this.proteceInfo.person = val;
			this.protectPersonPop = false;
		},

		timeCheck(val) {
			this.timeLimitDict = val;
			this.proteceInfo.time = val.view_time;
			this.timePop = false;
			this.setEndDate();
		},

		startDateCheck(val) {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				let date = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
				this.startSecond = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':' + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
				this.isNextSecondStart = true;
			} else {
				this.startSecond = '00:00:00';
				this.isNextSecondStart = false;
			}

			this.proteceInfo.start = this.$base.dateFormater(val);
			this.startDatePop = false;
			this.setEndDate();
		},

		// 计算终保日期
		setEndDate() {
			if (this.proteceInfo.time && this.proteceInfo.start) {
				let startDate = new Date(this.proteceInfo.start);
				let timeDict = this.timeLimitDict;
				let endMs = startDate.getTime() + (Number(timeDict.totalDays) - 1) * 24 * 60 * 60 * 1000;
				this.proteceInfo.end = this.$base.dateFormater(new Date(endMs));

				this.endDateMaxDate = new Date(endMs);

				if (timeDict.view_time.includes('-')) {
					let minDaysStr = timeDict.view_time.split('-')[0];
					let minEndMs = startDate.getTime() + (Number(minDaysStr) - 1) * 24 * 60 * 60 * 1000;
					this.endDateMinDate = new Date(minEndMs);
				} else {
					this.endDateMinDate = new Date(endMs);
				}
			}
		},

		endDateCheck(val) {
			this.proteceInfo.end = this.$base.dateFormater(val);
			this.endDatePop = false;
		},

		userTypeCheck(val) {
			if (this.userInfo.userType !== val.value) {
				// 清空
				this.userInfo.userName = '';
				this.userInfo.cardNum = '';
				this.userInfo.phone = '';
				this.userInfo.email = '';
				this.userInfo.birthday = '';
				this.userInfo.sex = '';
			}

			// 固定信息
			this.setFixdInfo();

			this.userTypeDict = val;
			this.userInfo.userType = val.value;
			this.userTypePop = false;
		},

		cardTypeCheck(val) {
			this.userInfo.cardType = val.label;
			this.cardTypePop = false;
		},

		groupCardTypeCheck(val) {
			this.userInfo.groupCardType = val.label;
			this.groupCardTypePop = false;
		},

		// 证件号码输入
		cardNumChange(val) {
			if (val && this.userInfo.cardType === '身份证') {
				// 处理大小写问题
				this.userInfo.cardNum = String(val).toLocaleUpperCase();
			}
			if (this.userInfo.cardType === '身份证' && Mtils.validation.isIdCard(this.userInfo.cardNum)) {
				// 计算
				let info = this.$base.getInfoFromIdNumber(val);
				this.userInfo.birthday = info.birthday;
				this.userInfo.sex = info.sex;
				this.currentDate = new Date(info.birthday);
			}
		},

		birthdayCheck(val) {
			this.userInfo.birthday = this.$base.dateFormater(val);
			this.birthdayPop = false;
		},

		sexCheck(val) {
			this.userInfo.sex = val.label;
			this.sexPop = false;
		},

		// 新增新增被保险人-打开页面
		addUser() {
			this.$router.push({
				name: 'addUser',
			});
		},

		// 新增被保险人-设置数据 idCompensation: id补偿字符，因为循环太快，在1ms中可以计算很多数据，id会重复
		setUserInfo(info, idCompensation = undefined) {
			if (!info.id) {
				info.id = String(Date.now());
				if (idCompensation !== undefined) {
					info.id += idCompensation;
				}
				this.userList.push(info);
			} else {
				this.userList.forEach(item => {
					if (item.id === info.id) {
						Object.assign(item, info);
					}
				});
			}
		},

		// 编辑被保人信息
		editUser(info) {
			this.$router.push({
				name: 'addUser',
				params: {
					info,
				},
			});
		},

		// 删除被保人
		deleteUser(id) {
			let ids = this.userList.map(item => item.id);
			let index = ids.indexOf(id);
			this.userList.splice(index, 1);

			// 执行重复检查
			this.validateRepeat();
		},

		// 去保障条款页面
		seeRules() {
			this.$router.push({
				name: 'safeguardClause',
				params: {
					productId: this.productInfo.id,
					comboId: this.$store.state.plainId,
				},
			});
		},

		seeRule(title) {
			this.infoPopTitle = title;
			this.rule_1_list.forEach(dict => {
				if (dict.typeDesc === title) {
					this.infoPopContent = dict.fixedInfo;
				}
			});
			this.infoPopShow = true;
		},

		nextDoc() {
			if (this.mustReadIndex < 3) {
				this.mustReadIndex++;
				this.isReading = true;
				this.$refs.countDown.reset();
			} else {
				this.rule_2 = true;
				this.mustReadPopShow = false;
			}
		},

		rule_2_check() {
			if (this.rule_2) {
				this.rule_2 = false;
				this.mustReadIndex = 1;
				this.isReading = true;
				this.mustReadPopShow = true;
				this.$nextTick(() => {
					this.$refs.countDown.reset();
				});
			}
		},

		finish() {
			this.isReading = false;
		},

		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 手机号码校验
		phoneCheck(val) {
			return regular.phone.test(val);
		},

		// 证件类型校验方法
		cardNumCheck(val, isAssured = false, assuredInfo = {}) {
			if (isAssured === true) {
				// 被保人
				if (assuredInfo.cardType === '身份证') {
					return Mtils.validation.isIdCard(val);
				}
				if (assuredInfo.cardType === '护照') {
					return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
				}
				if (assuredInfo.cardType === '军官证') {
					return regular.army.test(val);
				}
				if (assuredInfo.cardType === '港澳回乡证') {
					return regular.hkCard.test(val);
				}
				if (assuredInfo.cardType === '台胞证') {
					return regular.twCard.test(val);
				}
			} else {
				// 投保人
				if (this.userInfo.userType === '个人') {
					if (this.userInfo.cardType === '身份证') {
						return Mtils.validation.isIdCard(val);
					}
					if (this.userInfo.cardType === '护照') {
						return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
					}
					if (this.userInfo.cardType === '军官证') {
						return regular.army.test(val);
					}
					if (this.userInfo.cardType === '港澳回乡证') {
						return regular.hkCard.test(val);
					}
					if (this.userInfo.cardType === '台胞证') {
						return regular.twCard.test(val);
					}
				} else if (this.userInfo.userType === '团体') {
					if (this.userInfo.groupCardType === '统一社会信用代码') {
						return Mtils.validation.isCreditCode(val);
					}
				}
			}
			return true;
		},

		// 同投保人
		userSame() {
			if (this.$store.state.productId == 27555 && this.userInfo.cardType != '身份证') {
				return Toast('该产品证件类型只能为身份证');
			}
			this.$refs.form.validate().then(
				() => {
					if (!this.userList.some(item => item.cardNum === this.userInfo.cardNum)) {
						let newUserInfo = JSON.parse(JSON.stringify(this.userInfo));
						newUserInfo.id = String(Date.now());
						this.userList.push(newUserInfo);
						// this.userList.push(this.userInfo)
					}
				},
				() => {
					Toast('请先按要求把信息填写完整');
				},
			);
		},

		buy() {
			if (!this.rule_1 || !this.rule_2) {
				Toast('请先勾选条款');
				return false;
			}

			this.$refs.form.validate().then(
				() => {
					if (this.userList.length) {
						if (this.isNextSecondStart) {
							// 即时起保，从服务器获取时间戳,防止用户客户端时间不准
							Toast.loading({
								duration: 0, // 持续展示 toast
								forbidClick: true,
								message: '提交订单中',
							});
							http_getServerTime().then(resTP => {
								Toast.clear();
								this.submitForm(Number(resTP.timestamp)).then(res => {
									this.$store.commit('set_billId', res.id);
									khs.finish(res.id);
									this.$router.push({
										name: 'orderPay',
										params: {
											isRedict: false,
										},
									});
								});
							});
						} else {
							this.submitForm().then(res => {
								this.$store.commit('set_billId', res.id);
								khs.finish(res.id);
								this.$router.push({
									name: 'orderPay',
									params: {
										isRedict: false,
									},
								});
							});
						}
					} else {
						Toast('请先添加被保人');
					}
				},
				() => {
					Toast('请先按要求把信息填写完整');
				},
			);
		},

		// 提交订单
		submitForm(timestamp = 0) {
			let assuredList = this.userList.map(item => {
				return {
					name: item.userName,
					certificateType: cardType.filter(card => card.label === item.cardType)[0].value,
					certificateContent: item.cardNum,
					birthday: this.$base.submitDateFormatter(item.birthday),
					sex: item.sex,
					mobile: item.phone,
				};
			});

			let extentionList = [];
			if (this.travelShow) {
				extentionList.push({
					colName: this.travelFieldInfo.fieldName,
					colValue: JSON.stringify(this.travelCheckedList.map(item => item.value)),
				});
			}

			let send = {
				assuredList, // 被保人
				insurer: {
					insBirthday: this.$base.submitDateFormatter(this.userInfo.birthday),
					insCredentials: this.userInfo.cardNum,
					insCredentialsType: this.userTypeDict.name == 1 ? cardType.filter(card => card.label === this.userInfo.cardType)[0].value : groupCardType.filter(card => card.label === this.userInfo.groupCardType)[0].value,
					insEmail: this.userInfo.email,
					insName: this.userInfo.userName,
					insPhone: this.userInfo.phone,
					insSex: this.userInfo.sex,
					insType: this.userTypeDict.name,
				},
				extentionList: extentionList,
				insBill: {
					enableDate: `${this.$base.submitDateFormatter(this.proteceInfo.start)} ${this.isNextSecondStart ? this.$base.getTime(timestamp + this.$store.state.immediateExtendTime * 1000 * 60) : this.startSecond}`,
					disEnableDate: this.$base.submitDateFormatter(this.proteceInfo.end, 2), //终保日期
					id: '',
					insureType: '2', //出单方式
					proId: this.$store.state.productId, //产品ID
					remark: '',
					platform: 'H5',
					assuredSendMsg: 1,
				},
				optType: '2',
				productPriceVo: {
					comboId: this.$store.state.plainId,
					proId: this.$store.state.productId,
					viewTime: this.timeLimitDict.view_time,
					eleView1: '',
					eleView2: '',
					priceId: this.timeLimitDict.price_id,
					sex: '',
					viewAgeBelong: '',
					insureTime: this.timeLimitDict.insure_time,
					insureTimeUnit: this.timeLimitDict.insure_time_unit,
				},
			};
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交订单中',
			});
			return new Promise(resolve => {
				submitBill(send).then(
					res => {
						Toast.clear();
						Toast.success('订单提交成功');
						resolve(res.data);
					},
					err => {
						console.log(err);
					},
				);
			});
		},

		// 计算价格
		getPrice(num) {
			let send = {
				comboId: this.$store.state.plainId,
				eleView1: '',
				eleView2: '',
				insureTime: this.timeLimitDict.insure_time,
				insureTimeUnit: this.timeLimitDict.insure_time_unit,
				priceId: this.timeLimitDict.price_id,
				proId: this.$store.state.productId,
				sex: '',
				viewAgeBelong: '',
				viewTime: this.timeLimitDict.view_time,
			};
			getPrice(send).then(res => {
				this.price = this.$base.floatPrice(Number(res.priceInfo.price) * num);
			});
		},

		// 打开名单粘贴弹窗
		openNamePasteDialog() {
			this.nameString = '';
			this.namePasteDialogShow = true;
		},

		analysisToast(msg, type = 'text') {
			Toast({
				message: msg,
				className: 'analysis_toast',
				getContainer: '#create_order',
				type,
			});
		},

		// 解析名单
		analysisNameStr() {
			let rows = this.nameString.split('\n');

			// 200条数据检测
			if (rows.length > 200) {
				this.analysisToast('最大支持200条数据，请修改输入的数据', 'fail');
			}

			// 去除姓名中间的空格，只支持一个空格
			let nameString = this.removeIndex1Space(rows);

			this.analysisIng = true;
			analysisNameString(nameString).then(res => {
				this.analysisIng = false;
				this.nameSuccessLength = res.profList.length;
				this.nameErrorLength = res.errorList.length;

				// 解析结果中有错误信息
				if (this.nameErrorLength) {
					this.errorNameString = '';
					res.errorList.forEach(itemStr => {
						this.errorNameString += itemStr + '\n';
					});
					this.namePasteErrorDialogShow = true;

					// 初始化复制事件
					this.$nextTick(this.copyEventInit);
				}

				// 最多只解析200条信息
				let list = res.profList;
				if (this.userList.length + list.length > 200) {
					list.splice(200 - this.userList.length);
				}
				if (this.$store.state.productId == 27555) {
					let errInfoName = '';
					list = list.filter(item => {
						if (item.certificateType == '01') {
							return item;
						} else {
							errInfoName += item.name;
						}
					});
					if (errInfoName) {
						Toast(errInfoName + '证件号码只能为身份证');
					}
				}
				// 解析结果到被保险人名单中
				if (list.length) {
					list.forEach((item, index) => {
						this.setUserInfo(
							{
								userName: String(item.name).replace(' ', ''),
								cardType: cardType.filter(type => type.value === item.certificateType)[0].label,
								cardNum: item.certificateContent.toLocaleUpperCase(),
								birthday: item.birthday,
								sex: item.sex,
								phone: item.mobile,
								id: undefined,
							},
							index,
						);
					});
					// 执行校验方法
					this.chekUserInfoList();

					// 执行重复检查
					this.validateRepeat();
				}
				this.namePasteDialogShow = false;
			});
		},

		// 把textarea中取的值，处理每行前2个字符中的空格（为了处理姓名中间的空格），并返回textarea格式的字符串
		removeIndex1Space(rows) {
			let result = '';
			rows.forEach(row => {
				let trimRow = row.trim();

				// 去除特殊的字符
				trimRow = this.removeSpecialString(trimRow);

				if (trimRow.slice(0, 2).includes(' ')) {
					trimRow = trimRow.replace(' ', '');
				}
				result = result + trimRow + '\n';
			});
			return result;
		},

		// 去除特殊字符
		removeSpecialString(str) {
			let strCode = encodeURI(str);
			let specialCodeList = ['%E2%80%8B'];
			specialCodeList.forEach(code => {
				strCode = strCode.replace(code, '');
			});
			return decodeURI(strCode);
		},

		// 初始化复制事件
		copyEventInit() {
			let clipboard = new ClipboardJS(this.$refs.copyBtn);
			clipboard.on('success', e => {
				this.analysisToast('复制成功', 'success');
				e.clearSelection();
			});

			clipboard.on('error', e => {
				this.analysisToast('复制成功', 'fail');
			});
		},

		// 手动校验被保险人信息
		chekUserInfoList() {
			this.userList.forEach(dict => {
				dict.isError = !this.checkUserInfo(dict);
			});
		},

		// 校验被保险人信息方法，有错误即停止检查，并返回false。完全正确返回true
		checkUserInfo(info) {
			let result = true;
			outer: for (const key in info) {
				if (Object.hasOwnProperty.call(info, key) && Object.hasOwnProperty.call(this.rules, key)) {
					const val = info[key];
					let ruleList = this.rules[key];

					// 注意：break无法跳出foreach
					for (let i = 0; i < ruleList.length; i++) {
						const rule = ruleList[i];
						// 必填校验
						if (Object.hasOwnProperty.call(rule, 'required') && key !== 'phone') {
							if (rule.required && !val && val !== 0) {
								result = false;
								break outer;
							}
						}

						// 自定义方法校验
						if (Object.hasOwnProperty.call(rule, 'validator')) {
							if (rule.validator && (val || val === 0)) {
								if (rule.validator(val, true, info) !== true) {
									result = false;
									break outer;
								}
							}
						}
					}
				}
			}
			return result;
		},

		// 检查重复的证件号码，该方法回标记出所有的重复信息，比如：有三条一模一样的证件号码，会把这三条全标记出来
		validateRepeat(cb) {
			let repeatInfos = [];
			let idNumList = this.userList.map(row => row.cardNum);
			let repeatIds = [];
			for (let i = 0; i < idNumList.length; i++) {
				const num = idNumList[i];
				let otherNums = idNumList.slice(i + 1);
				let repeatIndex = otherNums.indexOf(num);
				if (repeatIndex !== -1) {
					repeatIds.push(num);
					this.userList[i].isRepeat = true;
					this.userList[i + repeatIndex + 1].isRepeat = true;
					repeatInfos.push(this.userList[i], this.userList[i + repeatIndex + 1]);
				} else {
					if (!repeatIds.includes(num)) {
						this.$set(this.userList[i], 'isRepeat', false);
					}
				}
			}
			if (cb) {
				cb(repeatInfos);
			}
		},
	},
};
</script>
