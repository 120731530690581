<style lang="scss" scoped>
.cell_title,
.cell_value {
	color: $font_color_thd;
	font-size: 0.24rem;
}

.product_detail {
	width: 100%;
	height: 100%;
	padding: 0.2rem 0.4rem 0;
	box-sizing: border-box;
}
.card_box {
	width: 100%;
	padding: 0.24rem;
	box-sizing: border-box;
	background: #ffffff;
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0, 0, 0, 0.2);
	border-radius: 0.12rem;
}

.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.sec_title {
	font-size: 0.28rem;
	color: $font_color_main;
	font-weight: bold;
}

.banner {
	display: flex;
	justify-content: center;

	img {
		width: 6.7rem;
		height: 3.74rem;
	}
}

.price_info {
	padding: 0.2rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.price {
		color: $color_active_4;
		display: flex;
		align-items: baseline;

		.integer {
			font-size: 0.42rem;
		}

		.icon {
			font-size: 0.3rem;
		}

		.float {
			font-size: 0.42rem;
		}
	}

	.sales {
		color: $font_color_info;
		font-size: 0.24rem;
	}
}

.product {
	margin-bottom: 0.2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	img {
		height: 0.25rem;
	}
}

.product_info {
	margin-bottom: 0.4rem;
	font-size: 0.24rem;
	color: rgba(0, 0, 0, 0.65);
	display: flex;
	align-items: center;

	.line {
		width: 1px;
		height: 0.25rem;
		background-color: $b_border_color;
		margin: 0 0.16rem;
	}

	.icon {
		margin-left: 0.16rem;
	}
}

.product_tags {
	display: flex;
	align-items: center;
	padding: 0.2rem 0;

	li {
		height: 0.32rem;
		font-size: 0.24rem;
		padding: 0.04rem 0.12rem;
		color: $color_active_2;
		background-color: rgba($color: $color_main, $alpha: 0.1);
		margin-left: 0.1rem;
	}

	.active {
		color: $color_active;
		background-color: rgba($color: $color_active, $alpha: 0.1);
		margin-left: 0;
	}
}

.plain {
	border-bottom: 0.02rem solid $b_border_color;
	padding-bottom: 0.2rem;
}

.plain_cont {
	padding: 0.2rem 0;
}

.duty {
	padding: 0.14rem 0.3rem;
	margin-top: 0.16rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.btn {
		font-size: 0.24rem;
		color: $font_color_main;

		.btn_text {
			margin-right: 0.1rem;
		}
	}
}

.duty_list {
	position: relative;

	li {
		padding: 0.24rem 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $font_color_main;
		font-size: 0.24rem;

		.money_box {
			display: flex;
			align-items: center;
		}

		.money {
			margin-right: 0.1rem;
		}
	}
	.see_more_line {
		position: absolute;
		bottom: 1.2rem;
		left: 0;
		height: 0.6rem;
		width: 100%;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
	}

	.see_more {
		margin: 0.12rem 0 0.06rem 0;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 80%);
		justify-content: center;
		.text {
			margin-right: 0.1rem;
		}
	}

	.line {
		padding: 0;
		height: 1px;
		background-color: $b_border_color;
	}

	::v-deep(.van-cell) {
		padding: 0.2rem 0;
		border-bottom: 0.02rem solid $b_border_color;
		font-size: 0.28rem;
		&:last-child {
			border-bottom: 0;
		}
	}
}

.count {
	margin: 0.4rem 0;
	height: 0.8rem;
	border-radius: 8px;
	font-size: 0.3rem;
	text-align: center;
	color: #fff;
	background-color: $color_main;
	line-height: 0.8rem;
}

.more_detail {
	font-size: 0.24rem;
	color: $font_color_info;

	.link {
		color: $color_main;
	}
}

.tab {
	padding: 0.2rem 0.3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 0;
	background-color: #fff;

	li {
		text-align: center;
	}

	.text {
		font-size: 0.3rem;
		font-weight: bold;
	}

	span {
		display: inline-block;
		width: 0.3rem;
		height: 0.06rem;
		background-color: #fff;
		margin-top: 0.14rem;
	}

	.active .text {
		color: $color_main;
	}

	.active span {
		background-color: $color_main;
	}
}

.tab_float {
	position: fixed;
	top: 0.88rem;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	box-shadow: 0 0 4px #ddd;
}

.tab_content {
	padding: 0.2rem 0.3rem 0.6rem;
	box-sizing: border-box;

	.banner {
		width: 100%;
		margin-bottom: 0.8rem;
	}

	.guide {
		.main_title {
			line-height: 0.3rem;
		}

		.sec_title {
			line-height: 0.28rem;
			margin-top: 0.3rem;
			margin-bottom: 0.2rem;
		}

		p {
			color: $font_color_sec;
			font-size: 0.24rem;
			line-height: 0.36rem;
			margin-bottom: 0.63rem;
		}
	}

	.question {
		padding-top: 0.32rem;

		.main_title {
			padding-bottom: 0.09rem;
			line-height: 0.3rem;
		}

		.cont {
			padding-top: 0.34rem;
		}

		.cont_line {
			border-top: 1px solid #dddddd;
		}

		.que,
		.ans {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
		}

		.icon {
			flex-shrink: 0;
			margin-right: 0.16rem;
		}

		.text {
			font-size: 0.24rem;
			line-height: 0.3rem;
			color: $font_color_main;
		}

		.ans {
			margin-top: 0.24rem;
			margin-bottom: 0.24rem;

			.text {
				color: $font_color_sec;
			}
		}

		.more_ans {
			font-size: 0;
			margin-bottom: 0.3rem;
			margin-left: 0.46rem;
			display: flex;
			align-items: center;

			.text {
				font-size: 0.24rem;
				color: $color_active_3;
				line-height: 0.24rem;
				margin-right: 0.1rem;
			}
		}
	}

	.example {
		margin-top: 0.8rem;

		p {
			font-size: 0.24rem;
			color: $font_color_main;
			line-height: 0.36rem;
			margin-top: 0.4rem;
			margin-bottom: 0.57rem;
			text-indent: 2em;
		}
	}

	.see_more {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0;

		span {
			font-size: 0.24rem;
			color: $font_color_main;
			line-height: 0.24rem;
		}

		.icon {
			margin-left: 0.1rem;
		}
	}
}

::v-deep .product_advantage {
	margin-bottom: 0.8rem;

	img {
		max-width: 100%;
	}
}

::v-deep .guide_content_box {
	margin-top: 0.3rem;

	img {
		max-width: 100%;
	}
}

.buy_btn_box {
	.cont {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1.1rem;
		display: flex;
		align-items: center;
		border-top: 1px solid #eeeeee;
		font-size: 0;

		.price {
			background-color: #fff;
			width: 3rem;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.text {
				font-size: 0.24rem;
				color: $font_color_main;
				line-height: 0.24rem;
				margin-right: 0.08rem;
			}

			.icon {
				font-size: 0.24rem;
				color: #ff5702;
			}

			.num {
				font-size: 0.36rem;
				color: #ff5702;
				line-height: 0.4rem;
			}

			.price_content {
				display: flex;
				align-items: baseline;
			}
		}

		.btn {
			height: 100%;
			flex: 1;
			font-size: 0.28rem;
			line-height: 1.1rem;
			text-align: center;
			color: #fff;
			background-image: url(../../assets/icon/buy.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}

	.back_dom {
		height: 100%;
		height: 1.1rem;
	}
}

::v-deep .age_box {
	position: relative;
	right: 6px;

	.van-dropdown-menu__bar {
		box-shadow: none;
	}

	.van-ellipsis {
		font-size: 0.28rem;
	}
}

.age_cell_title {
	display: flex;
	align-items: center;
	font-size: 0.28rem;
	color: #333;
}

.calculateForm_value {
	color: #333;
}

.calculateForm_content {
	padding-bottom: 1.1rem;
}

.calculateForm_product_name {
	font-size: 0.32rem;
	font-weight: bold;
}

.enter_process_pop {
	z-index: 3001 !important;

	.content {
		font-size: 0.24rem;
		color: $font_color_main;
		padding: 0.2rem 0.3rem;
	}

	.link {
		color: $color_main;
	}

	.section {
		padding: 0.2rem 0;
		text-indent: 2em;
		line-height: 0.4rem;
	}

	.footer {
		text-indent: 2em;
	}
}

.about {
	color: $font_color_main;
	font-size: 0;
	text-align: center;
	padding: 0.3rem 0.3rem 0.8rem;

	.header {
		font-size: 0.3rem;
		line-height: 0.48rem;
		font-weight: bold;
	}

	.title {
		font-size: 0.3rem;
		line-height: 0.4rem;
		margin-top: 0.2rem;
		margin-bottom: 0.1rem;
	}

	.section {
		font-size: 0.24rem;
	}

	ul {
		margin-top: 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-around;
		font-size: 0.2rem;

		li {
			display: flex;
			align-items: center;

			.icon {
				font-size: 0.6rem;
				color: $font_color_info;
			}

			.text {
				font-size: 0.16rem;
				margin-left: 0.1rem;

				p {
					line-height: 0.26rem;
				}
			}
		}
	}
}

::v-deep .calculateForm_content {
	.van-dropdown-item__content {
		max-height: 30vh;
	}
}

.line_box {
	padding: 0.5rem 0;

	.line {
		height: 1px;
		background-color: $b_border_color;
	}
}

.weChat_warning {
	font-size: 0.4rem;
	text-align: center;
}

::v-deep .ly-tab-item {
	align-items: flex-start !important;
	&:first-child {
		margin-left: 0;
	}
}

.nav_menu {
	float: right;
	font-size: 0.3rem;
	margin-left: -2rem;
}

.price_buy_btn {
	.cont {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0.8rem;
		display: flex;
		align-items: center;
		border-top: 1px solid #eeeeee;
		font-size: 0;
		z-index: 3000;

		.price {
			background-color: #fff;
			width: 3rem;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color_active_4;

			.text {
				font-size: 0.24rem;
				line-height: 0.24rem;
				color: $color_active_4;
				margin-right: 0.08rem;
			}

			.icon {
				font-size: 0.24rem;
				color: $color_active_4;
			}

			.num {
				font-size: 0.36rem;
				color: $color_active_4;
				line-height: 0.4rem;
			}

			.price_content {
				display: flex;
				align-items: baseline;
			}
		}

		.btn {
			height: 100%;
			flex: 1;
			font-size: 0.28rem;
			line-height: 0.8rem;
			text-align: center;
			color: #fff;
			background-color: $color_active_4;
		}
	}

	.back_dom {
		height: 100%;
		height: 1.1rem;
	}
}
.color_main {
	color: $b_color_main;
}
.title_name {
	color: $font_color_main;
}
.line_active {
	width: 0.08rem;
	height: 0.32rem;
	background: $color_active_5;
	margin-right: 0.2rem;
}
.hint_title{
	font-weight: bold;
}
</style>

<template>
	<div class="product_detail" ref="body" @scroll.passive="touchmove">
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信中打开</h4>
		</div>
		<div v-else>
			<!-- <top-nav ref="nav" :showBack="false"> -->
			<top-nav ref="nav" backImage bgColor="#F6F6FB" @back="back">
				<span class="title_name">产品详情</span>
			</top-nav>
			<div class="banner">
				<img v-if="productInfo.proPicUrlIndexBig" :src="productInfo.proPicUrlIndexBig" alt="" />
			</div>
			<div class="price_info">
				<span class="price">
					<span class="icon">￥</span>
					<span class="integer" v-text="priceInt">5</span>
					<span class="float" v-text="`.${priceFloat}`">.00</span>
				</span>
				<span class="sales" v-text="`销量：${productInfo.popularity}`"></span>
			</div>

			<!-- 保险名称 -->
			<div class="product">
				<span class="name main_title" v-text="productInfo.name">“耀之冠军”综合赛事保障</span>
				<img :src="insurerInfo.iconUrlSmallbrand" alt="" />
			</div>

			<div class="product_info">
				<span>保障年龄：</span>
				<span v-text="productInfo.insureAge"></span>
				<span class="line"></span>
				<span>保障期限：</span>
				<span v-text="productInfo.insureTime"></span>
				<span v-if="false" class="icon arrow_top"></span>
			</div>

			<!-- 广告语 -->
			<ul v-if="false" class="product_tags">
				<li v-for="(text, index) in ['适合30天内出行人士', '急性病保障', '多重保险性价比高']" :key="index" v-text="text" :class="{ active: !index }"></li>
			</ul>
			<div class="card_box">
				<!-- 保障计划 -->
				<div class="plain main_title">
					<div class="line_active"></div>
					保障计划
				</div>
				<div class="plain_cont">
					<ly-tab v-model="selectedIndex" @change="protectPlainChange" :items="protectPlainList" :options="options"> </ly-tab>
				</div>

				<!-- 保障责任 -->
				<div>
					<div class="btn" v-if="false">
						<span class="btn_text">展开</span>
						<span class="arrow_down_info"></span>
					</div>
				</div>
				<ul class="duty_list">
					<van-cell-group :border="false">
						<template v-for="(item, index) in dutyList">
							<van-cell :key="index" v-if="item.price" :title="item.contentName" :value="item.price" @click="showDutyDetail(item)" title-class="cell_title" value-class="cell_value" :border="false" is-link />
						</template>
					</van-cell-group>
					<li class="see_more_line" v-if="!dutyShowAll && dutyList.length"></li>
					<li class="see_more" v-show="!dutyShowAll && dutyList.length">
						<span class="text" @click="dutyShowAll = true">查看更多</span>
						<span class="arrow_down_info"></span>
					</li>
					<li class="line"></li>
				</ul>

				<!-- 保费试算 -->
				<div class="count" @click="calculate">保费试算</div>
				<div class="more_detail">
					更多详情，请阅读
					<span class="link" @click="showInsuranceInformation">《投保须知》</span>
					和
					<span class="link" @click="openProvisionPage">《投保条款》</span>
				</div>
			</div>

			<!-- 关于 -->
			<div class="about" v-if="false">
				<p class="header">-关于耀保网-</p>
				<p class="title">耀保网北京赛福哈博保险经纪旗下产品</p>
				<p class="section">是一家专注为企业定制行业风险管理解决方案的互联网保险服务平台。</p>
				<ul>
					<li>
						<span class="icon iconfont ybx_xingxing"></span>
						<div class="text">
							<p>定制化</p>
							<p>保险产品</p>
						</div>
					</li>
					<li>
						<span class="icon iconfont ybx_24xiaoshiqiantai"></span>
						<div class="text">
							<p>24小时</p>
							<p>服务热线</p>
						</div>
					</li>
					<li>
						<span class="icon iconfont ybx_kefu"></span>
						<div class="text">
							<p>专属顾问</p>
							<p>温馨服务</p>
						</div>
					</li>
				</ul>
			</div>

			<!-- 产品解读 -->
			<insureProductNarrate v-show="informationAreaShow" :information="information" :questionList="questionListShow" />

			<!-- <price-buy-btn :price="price" @buy="buy"></price-buy-btn> -->
			<div class="price_buy_btn">
				<div class="cont">
					<div class="price">
						<div class="price_content">
							<span class="text">价格</span>
							<span class="icon">￥</span>
							<span class="num">{{ price }}</span>
						</div>
					</div>
					<div class="btn" @click="buy">
						<slot>立即购买</slot>
					</div>
				</div>
				<div class="back_dom"></div>
			</div>

			<!-- 信息弹出层 -->
			<bottom-info-pop v-model="infoPop" :title="infpPopData.title" :content="infpPopData.text">
				<template v-if="infpPopData.title=='投保须知'&&Array.isArray(infpPopData.text)">
					<div v-for="(item,index) in infpPopData.text" :key="index">
						<div class="hint_title" v-html="item.title"></div>
						<div v-html="item.content"></div>
					</div>
				</template>
			</bottom-info-pop>

			<!-- 保费试算 -->
			<van-action-sheet v-model="calculatePop" title="保费试算">
				<div class="calculateForm_content">
					<van-cell-group :border="false">
						<van-cell :title="productInfo.name" title-class="calculateForm_product_name"></van-cell>
						<van-cell title="购买份数" :value="`${calculateForm.num}份`" value-class="calculateForm_value"> </van-cell>
						<van-cell title="承保年龄" title-class="age_cell_title" :border="true">
							<van-dropdown-menu slot="extra" class="age_box" direction="up">
								<van-dropdown-item v-model="calculateForm.age" :disabled="ageLimit.length === 1" :options="ageLimit" />
							</van-dropdown-menu>
						</van-cell>
						<van-cell title="保障期限" title-class="age_cell_title" :border="true">
							<van-dropdown-menu slot="extra" class="age_box" direction="up">
								<van-dropdown-item v-model="calculateForm.totalDays" @change="getPrice" :options="insureTimeList" />
							</van-dropdown-menu>
						</van-cell>
					</van-cell-group>
				</div>
			</van-action-sheet>

			<!-- 购买确认弹出层 -->
			<van-action-sheet v-model="warningPop" class="enter_process_pop" title="确认进入投保流程" @cancel="stopKhs" @click-overlay="stopKhs">
				<div class="content">
					<p class="header">尊敬的客户：</p>
					<p class="section">您即将进入投保流程，请仔细阅读保险条款。本保险产品由《<span class="link" v-text="insurerFullName"></span>》承保，由耀保网协助理赔。</p>
					<p class="footer">
						投保前请你仔细阅读
						<a class="link" v-if="docToConsumerLink" :href="docToConsumerLink" target="_blank">客户告知书</a>
						<span class="color_main" v-else @click="notification && (docShow = true)">客户告知书</span>
					</p>
				</div>
				<div class="count" @click="enterProcess">确认开始投保</div>
			</van-action-sheet>
			<!-- 客户告知书弹框 -->
			<bottomInfoPop :show="docShow" title="客户告知书" :content="notification" @change="docShow = false" />
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, ActionSheet, DropdownMenu, DropdownItem, Toast } from 'vant';
import { loginNoPwd, productDetail, productDictInfo, getPrice, getInsuerInfo } from '@/request/api';
import insureProductNarrate from '@/components/cpsInsureProductNarrate';
import {http_getProductComboConfig} from "@/request/insureV2"
import { cpsLoginCode } from '@/request/cps';
import vip from '@/config/customization';
export default {
	name: 'cpsProductDetail', //cps产品详情
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[ActionSheet.name]: ActionSheet,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem,
		[Toast.name]: Toast,
		insureProductNarrate,
	},
	data() {
		return {
			insInsureNotesVoList:[],//投保须知
			weChatWarningShow: false,
			scrollY: 0,
			// 产品信息
			productInfo: {},
			banner: '',

			// 保险公司信息
			insurerInfo: {},

			// 保障计划
			protectPlainList: [],
			selectedIndex: 0,
			options: {
				activeColor: '#1d98bd',
				labelKey: 'comboName',
				// 可在这里指定labelKey为你数据里文字对应的字段名
			},
			dutyShowAll: false,

			// 底部信息弹窗层
			infoPop: false,
			infpPopData: {
				title: '',
				text: '',
			},

			// tab是否要浮动在顶部
			tabFloat: false,
			tabOffsetTop: 0,
			tabActive: 0,

			// 其它信息
			information: {},
			informationAreaShow: false,

			// 保费试算弹窗
			calculatePop: false,
			// 承保年龄
			calculateForm: {
				num: 1,
				age: NaN,
				totalDays: NaN,
			},
			// 承保年龄
			ageLimit: [],
			// 保障期限
			insureTimeList: [],

			price: 0,

			// 理赔指南
			guideList: [],

			// 常见问题
			questionListShowAll: false,
			questionList: [],

			warningPop: false,
			docToConsumerLink: '',
			notification: '', //客户告知书富文本

			insurerFullName: '',

			product: {},
			backTo: '',
			isNewVersion: false,

			userId: '',
			docShow: false,
		};
	},
	computed: {
		// brandColor() {
		//     return this.$store.getters.get_cps_cps_brindInfo.brandColor
		// },
		priceInt() {
			let result = '0';
			if (this.protectPlainList.length) {
				let plain = this.protectPlainList[this.selectedIndex];
				let fullPrice = this.$base.floatPrice(plain.viewPrice);
				if (fullPrice) {
					result = fullPrice.split('.')[0];
				}
			}
			return result;
		},
		priceFloat() {
			let result = '00';
			if (this.protectPlainList.length) {
				let plain = this.protectPlainList[this.selectedIndex];
				let fullPrice = this.$base.floatPrice(plain.viewPrice);
				if (fullPrice) {
					result = fullPrice.split('.')[1];
				}
			}
			return result;
		},
		// 保障责任
		dutyList() {
			let result = [];
			if (this.protectPlainList.length) {
				if (!this.dutyShowAll) {
					result = this.protectPlainList[this.selectedIndex].contents.slice(0, 4);
				} else {
					result = this.protectPlainList[this.selectedIndex].contents;
				}
			}
			return result;
		},

		questionListShow() {
			let result = [];
			if (this.questionList.length) {
				if (!this.questionListShowAll) {
					result = this.questionList.slice(0, 3);
				} else {
					result = this.questionList;
				}
			}
			return result;
		},
	},
	async created() {
		this.backTo = this.$route.params.bc;
		const res = await cpsLoginCode(this.$route.query.code);
		this.$store.commit('set_cps_brindInfo', res.userInfo);
		this.$store.commit('set_productList', res.userInfo.product);
		this.$base.setCpsLogo()
		
		if (this.$route.query.productId) {
			this.$store.commit('set_cps_productId', this.$route.query.productId);
		}
		this.getProductDetail(this.$store.state.cps_productId);
		if (localStorage.getItem('cps_brindInfo')) {
			this.userId = JSON.parse(localStorage.getItem('cps_brindInfo')).userDesc;
		}
	},



	beforeRouteLeave(to, from, next) {
		this.scrollY = document.querySelector('.product_detail').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$nextTick(() => {
				vm.$refs.body.scrollTop = vm.scrollY;
			});
		});
	},
	methods: {
		stopKhs() {
			khs.stop();
		},
		// 判断是否微信环境
		isWeChat() {
			//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
			var ua = window.navigator.userAgent.toLowerCase();
			//通过正则表达式匹配ua中是否含有MicroMessenger字符串
			if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				return true;
			} else {
				return false;
			}
		},

		back() {
			if (this.backTo && this.backTo == 1) {
				// code: this.$store.state.cps_code.code,
				// return this.$router.push({ name: 'cpsProduct', query: { user: this.$store.state.cps_query.user, indexId: this.$store.state.cps_query.indexId } })
				return this.$router.push({ name: 'cpsProduct', query: { code: this.$store.state.cps_code.code } });
			}
			this.$router.go(-1);
		},

		// 刷新是跳转路由
		refresh() {
			if (this.$store.state.cps_productId == '') {
				this.$router.push('/cps/cpsProduct');
			}
		},

		login() {
			return new Promise(resolve => {
				loginNoPwd(this.$store.state.user).then(res => {
					this.userId = res.userInfo.id;
					resolve();
				});
			});
		},

		// 获取产品信息
		getProductDetail(productId) {
			productDetail(productId).then(res => {
				this.productInfo = res.product;
				this.insurerInfo = res.insurer;
				// this.protectPlainList   = res.combos;
				this.information = res.information;
				this.questionList = res.questionList;

				// 获取保障计划
				this.getPlain();

				this.informationAreaShow = true;
				this.tabActive = this.information.productCase ? 0 : 1;
				//版本
				this.isNewVersion = this.productInfo.versions == 1;
				// // 特殊用户定制图片
				// let conf = vip[this.$store.state.user];
				// if (conf) {
				//     // if (conf.banner && (!conf.product || conf.product === this.$store.state.productId)) {
				//     if (conf.banner && (!conf.product || conf.product === this.$store.state.cps_productId)) {
				//         this.productInfo.proPicUrlIndexBig = require(`@/assets/image/userSpecial/${conf.banner}`)
				//     }
				// }
			});
		},

		// 获取计划
		getPlain() {
			let send = {
				comboId: '',
				backInsureFlag: 1,
			};
			getInsuerInfo(this.productInfo.id, send).then(res => {
				this.$store.commit('set_cps_immediateExtendTime', res.product.immediateExtendTime);
				this.protectPlainList = res.combos;
				this.product = res.product;
				if (this.protectPlainList.length) {
					this.protectPlainChange(this.protectPlainList[0]);
				}
			});
		},

		// 显示底部弹出层
		showInfoPop(title, contentText) {
			this.infpPopData = {
				title: title,
				text: contentText,
			};
			this.infoPop = true;
		},

		// 查看保障责任详情
		showDutyDetail(detail) {
			this.showInfoPop(detail.contentName, detail.contentContent);
		},

		// 投保须知
		showInsuranceInformation() {
			// this.showInfoPop('投保须知', this.information.hint);
			this.showInfoPop('投保须知', this.insInsureNotesVoList?.length>0?this.insInsureNotesVoList:this.information.hint);

		},

		// 打开投保条款页面
		openProvisionPage() {
			this.$router.push({
				name: 'cpsSafeguardClause',
				params: {
					productId: this.$store.state.cps_productId,
					comboId: this.protectPlainList[this.selectedIndex].comboId,
				},
			});
		},

		// 切换保障计划
		async protectPlainChange(palinDict) {
			this.$store.commit('set_cps_plainId', palinDict.comboId);
			this.price = this.$base.floatPrice(palinDict.viewPrice);
				// 如果是v2产品，获取投保须知
			if(this.isNewVersion){
				const res=await http_getProductComboConfig({proId:this.productInfo.id,comboId:palinDict.comboId})
				if(res.insInsureNotesVoList.length>0){
					this.insInsureNotesVoList=res.insInsureNotesVoList
				}
			}
		},

		// 年龄段去重
		unique(arr) {
			let result = [];
			let compareValList = [];
			arr.forEach(item => {
				if (!compareValList.includes(item.text)) {
					result.push(item);
					compareValList.push(item.text);
				}
			});
			return result;
		},

		// 保费试算
		calculate() {
			productDictInfo(this.$store.state.cps_productId, this.protectPlainList[this.selectedIndex].comboId).then(res => {
				this.ageLimit = res.ageBelongList[0].ageLimit.map((item, index) => {
					return {
						text: `${item.min}-${item.max}周岁`,
						value: index,
						data: item,
					};
				});
				this.ageLimit = this.unique(this.ageLimit);

				this.insureTimeList = res.insureTimeList.map(item => {
					item.text = item.view_time;
					item.value = item.totalDays;
					return item;
				});

				if (this.ageLimit.length) {
					this.calculateForm.age = this.ageLimit[0].value;
				}

				if (this.insureTimeList.length) {
					this.calculateForm.totalDays = this.insureTimeList[0].value;
					this.getPrice(this.calculateForm.totalDays);
				}
				this.calculatePop = true;
			});
		},

		// 获取价格
		getPrice(val) {
			let timeDict = this.insureTimeList.filter(item => item.value === val)[0];
			let send = {
				comboId: this.protectPlainList[this.selectedIndex].comboId,
				eleView1: '',
				eleView2: '',
				insureTime: timeDict.insure_time,
				insureTimeUnit: timeDict.insure_time_unit,
				priceId: timeDict.price_id,
				proId: this.productInfo.id,
				sex: '',
				viewAgeBelong: '',
				viewTime: timeDict.view_time,
			};
			getPrice(send).then(res => {
				this.price = this.$base.floatPrice(res.priceInfo.price);
			});
		},

		// 进入流程
		enterProcess() {
			this.warningPop = false;
			this.$nextTick(() => {
				// let routeName = this.$store.state.user === 'c5dc7967a2d3dc0e5baf3f23b73f64a3' ? 'createOrderFixed' : 'createOrder';
				let routeName = 'cpsCreateOrder';
				if (!this.isNewVersion) {
					routeName = 'cpsCreateOrder';
					let conf = vip[this.$store.state.user];
					if (conf) {
						if (conf.orderPageName && (!conf.product || conf.product === this.$store.state.cps_productId)) {
							routeName = conf.orderPageName;
						}
					}
					this.$router.push({
						name: routeName,
						params: {
							productInfo: this.productInfo,
							information: this.information,
							product: this.product,
						},
						query: { user: this.$route.query.user, indexId: this.$route.query.indexId },
					});
				} else {
					routeName = 'cpsCreateOrderV2';
					this.$router.push({
						name: routeName,
						params: {
							proId: this.productInfo.id,
							comboId: this.protectPlainList[this.selectedIndex].comboId,
						},
					});
				}
				this.$store.commit('set_cps_createOrderRouteName', routeName);
			});
		},

		buy() {
			khs.start(this.userId);
			if (this.product.subTypeName && this.product.subTypeName.includes('线下专属')) {
				return Toast.fail('该产品不能购买');
			}
			let send = {
				comboId: this.protectPlainList[this.selectedIndex].comboId,
				backInsureFlag: '',
			};
			getInsuerInfo(this.productInfo.id, send).then(res => {
				this.product = res.product;
				if (res.bookList) {
					let bookList = res.bookList.filter(item => item.docType === '03');
					if (bookList.length) {
						this.docToConsumerLink = res.productFileServer + bookList[0].docLink;
					}
				}
				if (!this.docToConsumerLink) {
					this.notification = res.product.notification;
				}
				this.insurerFullName = res.insurer.insurerFullName;
				this.warningPop = true;
			});
		},
	},
};
</script>
